:root:root {
  --adm-color-primary: #008cf4;
}

:root:root .adm-tabs {
  --active-line-height: 0.053333rem;
  --fixed-active-line-width: 1.066667rem;
}

.adm-tabs-tab-active {
  font-weight: 600;
}

.adm-tabs-header {
  border-bottom: none !important;
}

.fun_troduction {
  padding: 1.066667rem 0 1.066667rem 0;
  background: #f8faff;
}

.fun_troduction h1 {
  text-align: center;
  margin-bottom: 0.8rem;
  font-size: 0.48rem;
  color: #333;
}

.fun_troduction .fun_box {
  overflow-y: auto;
  width: 10rem;
}

.fun_troduction .fun_box .fun_nav {
  width: 12rem;
}

.fun_troduction .fun_box .fun_nav li {
  display: inline-block;
  width: 2.266667rem;
  text-align: center;
  font-size: 0.4rem;
  color: #333;
  padding-bottom: 0.213333rem;
}

.fun_troduction .fun_box .fun_nav li .line {
  width: 1.066667rem;
  height: 0.053333rem;
  margin: 0 auto;
  margin-top: 0.213333rem;
  background-color: transparent;
  border-radius: 0.026667rem;
}

.fun_troduction .fun_box .fun_nav .active {
  color: #008cf4;
  font-weight: bold;
}

.fun_troduction .fun_box .fun_nav .active .line {
  background-color: #008cf4;
}

.fun_troduction .nav_desc {
  text-align: center;
  margin-top: 0.8rem;
}

.fun_troduction .nav_desc h1 {
  margin-bottom: 0.266667rem;
  font-size: 0.48rem;
  color: #333;
}

.fun_troduction .nav_desc .tag {
  min-width: 3.706667rem;
  padding: 0 0.2rem;
  height: 0.773333rem;
  line-height: 0.773333rem;
  display: inline-block;
  margin: 0 auto;
  background: #eceef7;
  border-radius: 0.08rem;
  font-size: 0.32rem;
  color: #333;
  position: relative;
}

.fun_troduction .nav_desc .tag span {
  text-align: center;
  padding: 0 0.266667rem;
}

.fun_troduction .nav_desc .tag span .icon {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.4rem;
  vertical-align: middle;
}

.fun_troduction .nav_desc .tag b {
  display: inline-block;
  font-weight: normal;
  width: 0.013333rem;
  height: 0.346667rem;
  background: #ccc;
  position: absolute;
  top: 0.2rem;
}

.fun_troduction .nav_desc .tag .line1 {
  left: 2.533333rem;
}

.fun_troduction .nav_desc .tag .line2 {
  left: 4.533333rem;
}

.fun_troduction .nav_desc .tag .line3 {
  left: 6.466667rem;
}

.fun_troduction .nav_desc .litter {
  margin-top: 0.266667rem;
  font-size: 0.32rem;
  color: #666;
}

.fun_troduction .nav_desc img {
  width: 7.466667rem;
  margin-bottom: 1.066667rem;
  margin-top: 0.8rem;
}