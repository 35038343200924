.pro_detail .part_one {
  text-align: center;
  padding: 1.066667rem 0;
  background: #f7f8fa;
}

.pro_detail .part_one h1 {
  font-size: 0.64rem;
  margin-bottom: 0.293333rem;
}

.pro_detail .part_one h1 span {
  color: #008cf4;
}

.pro_detail .part_one p {
  font-size: 0.4rem;
  line-height: 0.64rem;
}

.pro_detail .part_one img {
  width: 8.266667rem;
  margin: 0.8rem auto 0;
}

.pro_detail h1.title {
  margin-bottom: 0.8rem;
  text-align: center;
  font-size: 0.48rem;
}

.pro_detail .part_two {
  padding: 1.066667rem 0.333333rem;
  color: #fff;
  background-color: #008cf4;
}

.pro_detail .part_two ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
}

.pro_detail .part_two ul li {
  display: inline-block;
  width: 2.426667rem;
  height: 2.426667rem;
  margin: 0 0.333333rem;
  margin-bottom: 0.666667rem;
  opacity: 0.9;
  background: #fff;
  border-radius: 0.16rem;
  box-shadow: 0 0.04rem 0.133333rem 0 rgba(0,0,0,0.16);
}

.pro_detail .part_two ul li .icon {
  width: 0.773333rem;
  height: 0.773333rem;
  margin-top: 0.426667rem;
  margin-bottom: 0.066667rem;
}

.pro_detail .part_two ul li p {
  font-size: 0.4rem;
  color: #333;
}

.pro_detail .part_four,
.pro_detail .part_three {
  padding: 1.066667rem 0;
}

.pro_detail .part_four img,
.pro_detail .part_three img {
  width: 8.266667rem;
  margin: 0 auto 1.066667rem auto;
}

.pro_detail .part_four {
  background: #f8faff;
}

.pro_detail .part_isv_one {
  text-align: center;
  padding: 1.066667rem 0;
  background: #f7f8fa;
}

.pro_detail .part_isv_one h1 {
  font-size: 0.64rem;
  margin-bottom: 0.293333rem;
}

.pro_detail .part_isv_one h1 span {
  color: #008cf4;
}

.pro_detail .part_isv_one p {
  font-size: 0.4rem;
  line-height: 0.64rem;
}

.pro_detail .part_isv_one img {
  width: 8.266667rem;
}

.pro_detail .part_isv_one .btns_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.4rem auto 0.32rem;
}

.pro_detail .part_isv_one .btns_box .btn {
  width: 2.666667rem;
  height: 0.613333rem;
  display: inline-block;
  margin: 0 0.2rem;
}

.pro_detail .part_isv_two {
  padding: 1.066667rem 0.533333rem;
  color: #333333;
  background-color: #fff;
}

.pro_detail .part_isv_two .title {
  font-size: 0.48rem;
  font-weight: 500;
  margin-bottom: 0.266667rem;
}

.pro_detail .part_isv_two .sub_title {
  font-size: 0.4rem;
  text-align: center;
  margin-bottom: 0.533333rem;
}

.pro_detail .part_isv_two li {
  height: 2.186667rem;
  display: flex;
  border-radius: 0.16rem;
  box-shadow: 0 0 0.133333rem 0 #ececec;
  margin-bottom: 0.4rem;
  overflow: hidden;
}

.pro_detail .part_isv_two li .iconbox {
  width: 2.053333rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pro_detail .part_isv_two li .labelbox {
  flex: 1;
  font-size: 0.32rem;
  color: #717581;
  padding: 0.266667rem 0.533333rem;
  line-height: 0.506667rem;
}

.pro_detail .part_isv_two li .labelbox .labelbox-title {
  font-size: 0.4rem;
  color: #333;
  margin-bottom: 0.133333rem;
}

.pro_detail .part_isv_three {
  background: #f8faff;
  padding-bottom: 0.666667rem;
}

.pro_detail .part_isv_three .desc_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.pro_detail .part_isv_three .desc_item .desc_item_title {
  font-size: 0.48rem;
  font-weight: 500;
  margin-bottom: 0.266667rem;
  margin-top: 1.066667rem;
}

.pro_detail .part_isv_three .desc_item .desc_item_subtitle {
  font-size: 0.4rem;
  width: 7.733333rem;
  text-align: center;
  margin-bottom: 0.4rem;
}

.pro_detail .part_isv_three .desc_item img {
  width: 8.266667rem;
}