.home_modal {
  width: 9.2rem;
  margin: 2.186667rem 0.4rem 0 0.4rem;
  z-index: 999;
  background-color: transparent;
}

.home_modal .modal_body {
  padding: 0.8rem 0.533333rem;
  border-radius: 0.32rem;
  background-color: #fff;
}

.home_modal .modal_body h1 {
  height: 0.666667rem;
  text-align: center;
  font-size: 0.48rem;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.024rem;
  margin-bottom: 0.8rem;
}

.home_modal .modal_body form label {
  display: block;
  text-align: left;
  height: 0.56rem;
  font-size: 0.4rem;
  color: #333333;
  line-height: 0.746667rem;
}

.home_modal .modal_body form label span {
  margin-left: 0.066667rem;
  color: red;
}

.home_modal .modal_body form input {
  width: 8.133333rem;
  height: 1.04rem;
  padding: 0 0.266667rem;
  margin-top: 0.213333rem;
  margin-bottom: 0.666667rem;
  background: #fff;
  border: 0.013333rem solid #cbcfd5;
  border-radius: 0.16rem;
  font-size: 0.4rem;
}

.home_modal .modal_body form input:last-child {
  margin-bottom: 0.8rem;
}

.home_modal .modal_body form button {
  width: 8.133333rem;
  height: 1.04rem;
  background: #008cf4;
  border-radius: 0.16rem;
}

.home_modal .modal_body .tips_msg {
  height: 0.453333rem;
  margin-top: 0.533333rem;
  font-size: 0.32rem;
  text-align: left;
  color: #666;
  line-height: 0.506667rem;
}

.img_mobal {
  width: 8.4rem;
  margin: 2rem auto;
  text-align: center;
}

.img_mobal .modal_body {
  padding: 1.133333rem 0.506667rem;
  background: #fff;
  border-radius: 0.32rem;
}

.img_mobal .modal_body .erweima {
  width: 7.4rem;
  height: 6.373333rem;
  text-align: center;
  background-image: url("../../../public/image_m/home/h5_ewm_bg.png");
  background-size: 100% 100%;
}

.img_mobal .modal_body .erweima img {
  width: 5.333333rem;
  height: 5.333333rem;
  margin-top: 0.466667rem;
}

.img_mobal .modal_body p {
  text-align: center;
  font-size: 0.426667rem;
}

.img_mobal .modal_body .save {
  margin-top: 0.493333rem;
  margin-bottom: 0.213333rem;
}