.home_solution {
  padding: 1.066667rem 0 1.066667rem 0;
  text-align: center;
  background: #f8faff;
}

.home_solution h1 {
  margin-bottom: 0.8rem;
  font-size: 0.48rem;
  color: #333;
}

.home_solution .solution_nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home_solution .solution_nav li {
  display: inline-block;
  width: 5rem;
  text-align: center;
  font-size: 0.4rem;
  color: #333;
  padding-bottom: 0.213333rem;
}

.home_solution .solution_nav li .line {
  width: 2.293333rem;
  height: 0.053333rem;
  margin: 0 auto;
  margin-top: 0.213333rem;
  background-color: transparent;
  border-radius: 0.026667rem;
}

.home_solution .solution_nav .active {
  color: #008cf4;
  font-weight: bold;
}

.home_solution .solution_nav .active .line {
  background-color: #008cf4;
}

.home_solution .tag_list {
  margin-top: 0.8rem;
}

.home_solution .tag_list span {
  display: inline-block;
  padding: 0.186667rem 0.426667rem;
  font-size: 0.32rem;
  border: 0.013333rem solid transparent;
  border-radius: 0.16rem;
}

.home_solution .tag_list .row_one {
  text-align: left;
  margin-bottom: 0.4rem;
}

.home_solution .tag_list .row_two {
  text-align: left;
}

.home_solution .tag_list .color1 {
  margin-left: 0.533333rem;
  margin-right: 0.52rem;
  color: #008CF4;
  border-color: #60B7F8;
}

.home_solution .tag_list .color2 {
  color: #6242EB;
  border-color: #9C88F2;
}

.home_solution .tag_list .color3 {
  margin-left: 0.986667rem;
  margin-right: 0.653333rem;
  color: #FF654F;
  border-color: #FF988E;
}

.home_solution .tag_list .color4 {
  color: #67C239;
  border-color: #9FD883;
}

.home_solution img {
  width: 7.466667rem;
  margin-top: 0.8rem;
  margin-bottom: 1.066667rem;
}