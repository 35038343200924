.home_partner {
  padding: 1.066667rem 0 0.8rem 0;
  text-align: center;
  background-color: #fff;
}

.home_partner h1 {
  font-size: 0.48rem;
  color: #333;
}

.home_partner .swiper_row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0.533333rem;
}

.home_partner .swiper_row img {
  width: 2.8rem;
  height: 1.173333rem;
  margin-bottom: 0.533333rem;
  border-radius: 0.08rem;
  box-shadow: 0 0 0.133333rem 0 rgba(0,0,0,0.16);
}

.home_partner .tiga-dots-wrap {
  margin-top: 0.8rem;
  bottom: -0.066667rem;
  background-color: #fff;
}

.home_partner .tiga-dots-active {
  background: #008CF4;
}