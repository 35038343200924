html {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

body {
  color: #333;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

ul,
li {
  padding: 0;
  bottom: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
}

ul:not(.news_content ul) {
  padding: 0;
}

ul li {
  list-style-type: none;
}

a:not(.news_content a) {
  text-decoration: none;
  color: #333;
}

* {
  -webkit-overflow-scrolling: touch;
}

input {
  outline: 0;
  -webkit-appearance: none;
}

select::-ms-expand {
  display: none;
}

select {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: url(../../public/image_m/arrow.png) no-repeat 95% center !important;
  background-size: 0.32rem 0.186667rem !important;
  padding-right: 0.186667rem;
  color: #333;
}

option::-ms-expand {
  display: none;
}

option {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #333;
}

option:hover {
  color: #fff;
  background-color: #1e90ff;
}

select:focus,
input:focus {
  outline: none;
  border: none;
}

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
}

.moreTwo {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.hideScroll::-moz-scrollbar {
  display: none;
}

.hideScroll::-ms-scrollbar {
  display: none;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.thum_opacity {
  animation: fadeInAnimation ease 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

button.mainBtn {
  width: 3.466667rem;
  height: 1.04rem;
  line-height: 1.04rem;
  text-align: center;
  font-size: 0.4rem;
  color: #fff;
  background: #008cf4;
  border-radius: 0.16rem;
  border: none;
}

button.mainBtn .icon {
  width: 0.346667rem;
  height: 0.32rem;
  margin-left: 0.24rem;
  vertical-align: unset;
}

.modal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.52);
  z-index: 9999;
  overflow: auto;
}

.modal .modal_footer span {
  display: inline-block;
  width: 1.04rem;
  height: 1.04rem;
  margin-top: 1.066667rem;
  background: #ffffff;
  border-radius: 50%;
}

.modal .modal_footer .icon {
  width: 0.426667rem;
  height: 0.426667rem;
  margin: 0.306667rem;
}

.text_desc {
  padding: 0 0.533333rem;
}

.text_desc li {
  display: flex;
  align-items: baseline;
  padding: 0 0.533333rem;
  line-height: 0.64rem;
  margin-bottom: 0.533333rem;
  font-size: 0.4rem;
  word-break: break-all;
}

.text_desc li b {
  display: inline-block;
  width: 0.213333rem;
  height: 0.213333rem;
  margin-right: 0.213333rem;
  background: #008cf4;
  border-radius: 50%;
}

.text_desc li p {
  flex: 1;
}