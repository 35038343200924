.menu li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.453333rem 0 0.44rem 0;
  margin: 0 0.4rem;
  border-bottom: 0.013333rem solid #ddd;
  overflow: hidden;
  font-size: 0.426667rem;
  color: #333;
}

.menu li .icon {
  width: 0.4rem;
  height: 0.6rem;
}

.menu li.noBorder {
  border-bottom: none;
}

.menu .down_menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0.573333rem;
}

.menu .down_menu span {
  display: inline-block;
  width: 33.33%;
  text-align: center;
  font-size: 0.4rem;
  margin-bottom: 0.533333rem;
}

.menu .active {
  color: #008CF4;
}