.layput .content {
  margin-top: 1.306667rem;
}

.layput .back_top {
  position: absolute;
  bottom: 0.666667rem;
  right: 0.266667rem;
  width: 1.04rem;
  height: 1.04rem;
  text-align: center;
  background: #008CF4;
  border-radius: 50%;
  z-index: 999999;
}

.layput .back_top .icon {
  width: 0.533333rem;
  height: 0.533333rem;
  margin: 0.24rem 0.186667rem;
}