.partner .part_one {
  padding: 1.066667rem 0;
  text-align: center;
  background-color: #f7f8fa;
}

.partner .part_one h1 {
  margin-bottom: 0.293333rem;
  font-size: 0.64rem;
  letter-spacing: 0.032rem;
}

.partner .part_one p {
  font-size: 0.4rem;
  line-height: 0.64rem;
}

.partner .part_one img {
  margin-top: 0.8rem;
  width: 8.266667rem;
}

.partner .toggle {
  padding: 1.066667rem 1.066667rem 0.8rem 1.066667rem;
}

.partner .toggle ul {
  display: flex;
  align-items: center;
  width: 7.866667rem;
  height: 1.04rem;
  border-radius: 0.533333rem;
}

.partner .toggle ul li {
  width: 50%;
  height: 1.04rem;
  line-height: 1.04rem;
  display: inline-block;
  color: #333;
  text-align: center;
  font-size: 0.4rem;
}

.partner .toggle ul li.active {
  width: 4rem;
  color: #fff;
  background: #008cf4;
  border-radius: 0.52rem;
}

.partner .part_four {
  padding: 1.066667rem 0.533333rem 1.2rem;
  background-color: #008cf4;
}

.partner .part_four h1 {
  color: #fff;
  font-size: 0.48rem;
  margin-bottom: 0.8rem;
  text-align: center;
}

.partner .part_four ul {
  display: flex;
  justify-content: space-between;
}

.partner .part_four ul li {
  width: 1.813333rem;
  height: 3.12rem;
  text-align: center;
  background: #e6f3fd;
  border-radius: 0.16rem;
  box-shadow: 0 0.04rem 0.133333rem 0 rgba(0,0,0,0.16);
}

.partner .part_four ul li .icon {
  width: 0.853333rem;
  height: 0.853333rem;
  margin: 0.4rem 0 0.133333rem 0;
}

.partner .part_four ul li p {
  font-size: 0.4rem;
  line-height: 0.506667rem;
}

.partner .title {
  text-align: center;
  font-size: 0.48rem;
  margin-bottom: 0.266667rem;
  letter-spacing: 0.024rem;
}

.partner .title_desc {
  text-align: center;
  margin-bottom: 0.533333rem;
  font-size: 0.4rem;
}

.partner .agent_service_one {
  padding-bottom: 1.066667rem;
}

.partner .agent_service_one ul {
  padding: 0 0.533333rem;
}

.partner .agent_service_one ul li {
  display: flex;
  align-items: center;
  width: 8.933333rem;
  height: 2.186667rem;
  margin-bottom: 0.266667rem;
  border-radius: 0.16rem;
  box-shadow: 0 0 0.133333rem 0 #ececec;
}

.partner .agent_service_one ul li span {
  display: inline-block;
  width: 2.053333rem;
  height: 2.186667rem;
  line-height: 2.186667rem;
  text-align: center;
  background: #008cf4;
  border-radius: 0.16rem 0 0 0.16rem;
}

.partner .agent_service_one ul li span .icon {
  width: 0.853333rem;
  height: 0.853333rem;
}

.partner .agent_service_one ul li .desc {
  flex: 1;
  padding-left: 0.533333rem;
}

.partner .agent_service_one ul li .desc h1 {
  font-size: 0.4rem;
  margin-bottom: 0.133333rem;
  font-weight: normal;
}

.partner .agent_service_one ul li .desc p {
  font-size: 0.32rem;
  line-height: 0.506667rem;
  color: #717581;
}

.partner .agent_service_form {
  padding: 1.066667rem 0;
}

.partner .agent_service_form .tips {
  text-align: center;
  margin-bottom: 0.8rem;
  font-size: 0.32rem;
}

.partner .agent_service_form form {
  padding: 0 0.8rem;
  font-size: 0.4rem;
}

.partner .agent_service_form form label {
  display: block;
  margin-bottom: 0.213333rem;
}

.partner .agent_service_form form label span {
  color: red;
}

.partner .agent_service_form form select,
.partner .agent_service_form form input {
  width: 8.4rem;
  padding: 0 0.266667rem;
  height: 1.04rem;
  line-height: 1.04rem;
  margin-bottom: 0.666667rem;
  border: 0.013333rem solid #cbcfd5;
  border-radius: 0.16rem;
  font-size: 0.4rem;
}

.partner .agent_service_form form select {
  width: 4rem;
}

.partner .agent_service_form form select[name='city'] {
  margin-left: 0.4rem;
}

.partner .agent_service_form form button {
  width: 8.4rem;
}