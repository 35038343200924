.header .nav_title {
  position: fixed;
  top: 0;
  left: 0;
  width: 10rem;
  padding: 0 0.4rem;
  height: 1.306667rem;
  background-color: #fff;
  z-index: 2;
  box-shadow: 0 0.026667rem 0.053333rem 0 rgba(0,0,0,0.04);
  transform: translate3d(0, 0, 0);
  -webkit-overflow-scroll: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .nav_title img.logo {
  width: 2.24rem;
  height: 0.48rem;
}

.header .nav_title .left_logo .logo_fws {
  width: 2.24rem;
  margin-left: 0.4rem;
}

.header .nav_title .icon {
  float: right;
  width: 0.506667rem;
}

.header .modal {
  display: block;
  background-color: #fff;
}

.header .modal .modal_btn {
  height: 1.306667rem;
  padding: 0.4rem;
  box-shadow: 0 0.026667rem 0.053333rem 0 rgba(0,0,0,0.04);
}

.header .modal .modal_btn img {
  float: left;
  width: 2.24rem;
  height: 0.48rem;
}

.header .modal .modal_btn .icon {
  float: right;
  width: 0.426667rem;
  height: 0.426667rem;
}