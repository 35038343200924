.pagination {
  width: 100%;
}

.pagination .make_page {
  width: 100%;
  margin-top: 0.933333rem;
}

.pagination .make_page button {
  width: 2.933333rem;
  height: 1.04rem;
  line-height: 1.04rem;
  text-align: center;
  background: #ffffff;
  border: 0.013333rem solid #cccccc;
  border-radius: 0.106667rem;
  font-size: 0.4rem;
  font-weight: 400;
  color: #333333;
}

.pagination .make_page button:disabled {
  border-color: #cccccc;
  color: #ccc;
}

.pagination .make_page>div {
  width: 33.333333%;
  display: inline-block;
}

.pagination .make_page .prex {
  text-align: left;
}

.pagination .make_page .total {
  text-align: center;
  font-size: 0.4rem;
}

.pagination .make_page .total b {
  color: #008CF4;
  font-weight: normal;
}

.pagination .make_page .total span {
  margin: 0 0.133333rem;
}

.pagination .make_page .next {
  text-align: right;
}