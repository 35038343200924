.home .content {
  margin-top: 1.306667rem;
}

.home .content .first_part {
  width: 10rem;
  padding: 1.066667rem 0;
  text-align: center;
  color: #333;
  background: #f8faff;
}

.home .content .first_part .title {
  padding-bottom: 0.266667rem;
  font-size: 0.613333rem;
}

.home .content .first_part .title_son {
  font-size: 0.4rem;
}

.home .content .first_part .first_img {
  width: 8.266667rem;
  height: 5.333333rem;
  margin: 0.666667rem auto 0 auto;
}

.home .content .first_part .icon_list {
  margin-top: 0.4rem;
  text-align: center;
  font-size: 0.293333rem;
}

.home .content .first_part .icon_list span {
  display: inline-block;
  padding: 0 0.266667rem;
}

.home .content .first_part .icon_list span img,
.home .content .first_part .icon_list span .icon {
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.133333rem;
  vertical-align: middle;
}

.home .content .first_part .icon_list span:nth-child(2) {
  border-left: 0.026667rem solid #ccc;
  border-right: 0.026667rem solid #ccc;
}

.home .content .four_part {
  padding: 1.066667rem 0 1.066667rem 0;
}

.home .content .four_part h1 {
  margin-bottom: 0.8rem;
  text-align: center;
  font-size: 0.48rem;
  color: #333;
}

.home .content .four_part li {
  display: flex;
  width: 9.2rem;
  height: 3.466667rem;
  margin: 0.4rem auto;
  border-radius: 0.133333rem;
  box-shadow: 0 0 0.213333rem 0 #ebebeb;
}

.home .content .four_part li img {
  width: 3.466667rem;
  height: 3.466667rem;
  border-radius: 0.133333rem 0 0 0.133333rem;
}

.home .content .four_part li .desc {
  flex: 1;
  padding: 0.346667rem 0.4rem;
}

.home .content .four_part li .desc h2 {
  margin-bottom: 0.266667rem;
  font-size: 0.426667rem;
  color: #333;
}

.home .content .four_part li .desc p {
  font-size: 0.373333rem;
  color: #666;
  word-break: break-all;
  line-height: 0.613333rem;
}