.solution .title {
  margin-bottom: 0.8rem;
  font-size: 0.48rem;
  text-align: center;
}

.solution .part_one {
  padding: 1.066667rem 0;
  text-align: center;
  background-color: #f7f8fa;
}

.solution .part_one h1 {
  font-size: 0.64rem;
}

.solution .part_one ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 0.533333rem;
  margin-bottom: 0.8rem;
}

.solution .part_one ul li {
  width: 4.133333rem;
  height: 0.906667rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.4rem;
  font-size: 0.4rem;
  background: rgba(0,140,244,0.06);
  border: 0.013333rem solid #ddd;
  border-radius: 0.16rem;
}

.solution .part_one ul li .icon {
  width: 0.373333rem;
  height: 0.373333rem;
  margin-right: 0.133333rem;
  vertical-align: revert;
}

.solution .part_one img {
  width: 8.266667rem;
}

.solution .part_three,
.solution .part_two {
  padding: 1.066667rem 0;
}

.solution .part_three ul,
.solution .part_two ul {
  padding: 0 0.533333rem;
}

.solution .part_three ul li,
.solution .part_two ul li {
  display: flex;
  margin-bottom: 1.066667rem;
}

.solution .part_three ul li .icon_left,
.solution .part_two ul li .icon_left {
  width: 0.533333rem;
  height: 0.4rem;
  padding-right: 0.133333rem;
  margin-top: 0.066667rem;
}

.solution .part_three ul li .right,
.solution .part_two ul li .right {
  flex: 1;
}

.solution .part_three ul li .right h1,
.solution .part_two ul li .right h1 {
  font-size: 0.4rem;
  font-weight: 400;
}

.solution .part_three ul li .right p,
.solution .part_two ul li .right p {
  font-size: 0.32rem;
  color: #717581;
  line-height: 0.506667rem;
  margin-top: 0.266667rem;
  margin-bottom: 0.533333rem;
}

.solution .part_three ul li .right img,
.solution .part_two ul li .right img {
  width: 4.266667rem;
  height: 3.2rem;
}

.solution .part_three ul li:last-child,
.solution .part_two ul li:last-child {
  margin-bottom: 0;
}

.solution .part_three {
  background: #f8faff;
}