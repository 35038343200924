.news .top_news {
  padding: 0.8rem 0.533333rem;
  background-color: #f7f8fa;
}

.news .top_news h1 {
  font-size: 0.64rem;
  margin-bottom: 0.533333rem;
}

.news .top_news .img_box {
  width: 8.933333rem;
  height: 5.946667rem;
}

.news .top_news img {
  width: 100%;
  height: 100%;
  border-radius: 0.16rem;
}

.news .list_news h1 {
  padding: 1.066667rem 0;
  text-align: center;
  font-size: 0.48rem;
}

.news .list_news ul li {
  display: flex;
  align-items: center;
  padding: 0.4rem 0.533333rem;
  border-bottom: 0.013333rem solid #f2f2f2;
}

.news .list_news ul li .row_left {
  flex: 1;
}

.news .list_news ul li .row_left p {
  width: 5.6rem;
  font-size: 0.4rem;
  line-height: 0.64rem;
}

.news .list_news ul li .row_left span {
  height: 0.453333rem;
  font-size: 0.32rem;
  color: #999;
  line-height: 0.346667rem;
}

.news .list_news ul li .img_box {
  width: 2.933333rem;
  height: 2.666667rem;
}

.news .list_news ul li img {
  width: 100%;
  height: 100%;
  border-radius: 0.16rem;
}

.news .list_news ul li:first-child {
  border-top: 0.013333rem solid #f2f2f2;
}

.news .page_news {
  margin: -0.133333rem 0.533333rem 1.066667rem 0.533333rem;
}

.news_detail {
  padding: 0.8rem 0.533333rem;
}

.news_detail .news_title {
  line-height: 0.906667rem;
  font-size: 0.56rem;
}

.news_detail .news_time {
  margin: 0.533333rem 0;
  font-size: 0.4rem;
  color: #999;
}

.news_detail .news_content {
  margin-top: 0.4rem;
}

.news_detail .news_content video,
.news_detail .news_content img {
  max-width: 100%;
  height: auto;
}

.news_detail .news_content ol,
.news_detail .news_content ul {
  margin: 0;
  padding: auto;
}

.news_detail .news_content ol li,
.news_detail .news_content ul li {
  list-style: inherit;
}

.news_detail .news_content a {
  text-decoration: initial;
}