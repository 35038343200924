.service .service_two {
  padding: 1.066667rem 0;
  background-color: #F8FAFF;
}

.service .service_two .feature {
  display: flex;
  align-items: center;
  margin-bottom: 0.533333rem;
  padding-left: 1.093333rem;
  padding-right: 0.866667rem;
}

.service .service_two .feature span {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  text-align: center;
  margin-right: 0.266667rem;
  color: #fff;
  background: #008cf4;
  border-radius: 0.16rem;
}

.service .service_two .feature span .icon {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-top: 0.333333rem;
  vertical-align: inherit;
}

.service .service_two .feature p {
  flex: 1;
  font-size: 0.4rem;
}

.service .service_two .oth {
  padding-left: 1.36rem;
}

.service .service_two .oth span {
  background-color: #F7BB33;
}

.service .service_two img {
  width: 8.266667rem;
  margin: 0 0.866667rem;
}