.agent {
  padding-bottom: 1.066667rem;
}

.agent .agent_two {
  padding: 1.066667rem 0;
  background: #f8faff;
}

.agent .agent_two h1 {
  margin-bottom: 0.8rem;
}

.agent .agent_two ul {
  padding: 0 0.533333rem;
}

.agent .agent_two ul li {
  display: flex;
  align-items: center;
  width: 8.933333rem;
  margin-bottom: 0.533333rem;
}

.agent .agent_two ul li span {
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  text-align: center;
  background: #008cf4;
  border-radius: 0.16rem;
}

.agent .agent_two ul li span .icon {
  width: 0.533333rem;
  height: 0.533333rem;
  margin-top: 0.333333rem;
  vertical-align: inherit;
}

.agent .agent_two ul li p {
  flex: 1;
  padding-left: 0.266667rem;
  line-height: 0.64rem;
  font-size: 0.4rem;
}

.agent .agent_two img {
  width: 8.266667rem;
  margin: 0 0.866667rem 0;
}