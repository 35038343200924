.free_use {
  text-align: center;
  padding: 1.066667rem 0 1.066667rem 0;
  color: #333;
  background-image: url("../../../../../public/image_m/home/h5_index_bg_mfsy.png");
  background-size: 100% 100%;
}

.free_use .title {
  font-size: 0.48rem;
}

.free_use .logo {
  margin-top: 0.4rem;
  margin-bottom: 0.48rem;
  width: 4rem;
}

.free_use .title_son {
  margin-bottom: 0.8rem;
  font-size: 0.373333rem;
}