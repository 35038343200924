.personnel {
  text-align: center;
  padding: 1.066667rem 0 1.066667rem 0;
}

.personnel h1 {
  margin-bottom: 0.266667rem;
  font-size: 0.48rem;
  color: #333;
}

.personnel .desc_title {
  font-size: 0.4rem;
  color: #666;
}

.personnel .nav {
  position: relative;
  color: #fff;
}

.personnel .nav .nav_frag .box {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: rgba(0,139,245,0.15);
  border-radius: 50%;
}

.personnel .nav .nav_frag .box .box_con {
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #008cf4;
  border-radius: 50%;
}

.personnel .nav .nav_frag .box .box_con p {
  line-height: 0.4rem;
  word-break: break-all;
  font-size: 0.32rem;
}

.personnel .nav .nav_frag .box:nth-child(4) {
  width: 1.6rem;
  height: 1.6rem;
}

.personnel .nav .nav_frag .box:nth-child(4) .box_con {
  width: 1.333333rem;
  height: 1.333333rem;
}

.personnel .nav .nav_frag .pos_one {
  right: 1.4rem;
  top: 3.56rem;
}

.personnel .nav .nav_frag .pos_two {
  right: 3.16rem;
  top: 6.133333rem;
}

.personnel .nav .nav_frag .pos_three {
  left: 1.733333rem;
  top: 5.173333rem;
}

.personnel .nav .nav_frag .pos_four {
  left: 4.2rem;
  top: 3.733333rem;
}

.personnel .nav .nav_frag .pos_five {
  left: 1.733333rem;
  top: 2rem;
}

.personnel .nav .nav_frag .pos_six {
  right: 3.16rem;
  top: 1.053333rem;
}

.personnel .nav .nav_frag .active {
  background-color: rgba(103,194,57,0.15);
}

.personnel .nav .nav_frag .active .box_con {
  background: #67c239;
}

.personnel .nav .nav_frag .active .box_con p {
  font-weight: bold;
  font-size: 0.373333rem;
}

.personnel .nav .nav_bg {
  width: 8rem;
  height: 8rem;
  margin: 0.533333rem auto;
}

.personnel ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.personnel ul li {
  flex: 1;
  text-align: center;
}

.personnel ul li .icon {
  width: 1.226667rem;
  height: 1.226667rem;
  border-radius: 50%;
}

.personnel ul li p {
  margin-top: 0.32rem;
  font-size: 0.32rem;
}