.about_us .part_one {
  padding: 1.066667rem 0;
  text-align: center;
  background-color: #f7f8fa;
}

.about_us .part_one h1 {
  font-size: 0.64rem;
}

.about_us .part_one ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 0.533333rem;
  margin-bottom: 0.8rem;
}

.about_us .part_one ul li {
  width: 4.133333rem;
  height: 0.906667rem;
  text-align: center;
  line-height: 0.906667rem;
  margin-bottom: 0.4rem;
  font-size: 0.4rem;
  background: rgba(0,140,244,0.06);
  border: 0.013333rem solid rgba(0,140,244,0.6);
  border-radius: 0.16rem;
}

.about_us .part_one ul li .icon {
  width: 0.373333rem;
  height: 0.373333rem;
  margin-right: 0.133333rem;
  vertical-align: revert;
}

.about_us .part_one img {
  width: 8.266667rem;
}

.about_us .part_two {
  padding: 1.066667rem 0.533333rem;
}

.about_us .part_two h1 {
  text-align: center;
  margin-bottom: 0.8rem;
  font-size: 0.48rem;
}

.about_us .part_two p {
  width: 8.933333rem;
  font-size: 0.4rem;
  font-weight: 400;
  color: #54565d;
  line-height: 0.746667rem;
}

.about_us .part_two img {
  width: 8.266667rem;
  margin: 0.533333rem 0.533333rem 0;
}

.about_us .part_three {
  padding: 1.066667rem 0 0.533333rem 0;
  background-color: #f8faff;
}

.about_us .part_three .title {
  margin-bottom: 0.8rem;
  font-size: 0.48rem;
  text-align: center;
}

.about_us .part_three ul {
  padding: 0 0.533333rem;
}

.about_us .part_three ul li {
  display: flex;
  margin-bottom: 0.533333rem;
}

.about_us .part_three ul li .icon_left {
  width: 0.533333rem;
  height: 0.4rem;
  padding-right: 0.133333rem;
  margin-top: 0.066667rem;
}

.about_us .part_three ul li .right {
  flex: 1;
}

.about_us .part_three ul li .right h1 {
  font-size: 0.4rem;
  font-weight: 400;
}

.about_us .part_three ul li .right p {
  font-size: 0.4rem;
  color: #717581;
  line-height: 0.506667rem;
  margin-top: 0.266667rem;
}

.about_us .part_three ul li .right p.active {
  color: #008cf4;
}

.about_us .part_three ul li .right img {
  width: 4.266667rem;
  height: 3.2rem;
}

.about_us .about_map {
  z-index: 1;
  padding: 0 0.533333rem 1.333333rem 0.533333rem;
  height: 6.666667rem;
  background-color: #f8faff;
  position: relative;
}

.about_us .about_map .amap-container {
  z-index: 2;
}

.about_us .about_map .amap-info-content.amap-info-outer {
  width: 1.333333rem;
  height: 0.64rem;
  background: #ffffff;
  box-shadow: 0 0.026667rem 0.053333rem 0 #cccccc;
  padding: 0;
  text-align: center;
  line-height: 0.533333rem;
}

.about_us .about_map a.amap-info-close {
  display: none;
}

.about_us .about_map img {
  position: absolute;
  width: 0.986667rem;
  height: 0.8rem;
}

.about_us .about_map .top_left {
  left: 0.266667rem;
  top: -0.266667rem;
}

.about_us .about_map .bottom_right {
  position: absolute;
  right: 0.266667rem;
  bottom: 1.066667rem;
}