.footer .record {
  text-align: center;
  padding: 0.533333rem 0 0.4rem 0;
  background: #f0f2f8;
}

.footer .record .record_top .logo {
  width: 2.4rem;
}

.footer .record .record_top p {
  font-size: 0.373333rem;
  line-height: 0.613333rem;
  margin-bottom: 0.533333rem;
}

.footer .record .record_top .share {
  text-align: center;
}

.footer .record .record_top .share .icon {
  width: 1.04rem;
  height: 1.04rem;
  border-radius: 50%;
}

.footer .record .record_top .share .icon:first-child {
  margin-right: 0.8rem;
}

.footer .record .line {
  width: 9.2rem;
  height: 0.013333rem;
  margin: 0.533333rem auto 0.4rem auto;
  background: #e0e0e0;
}

.footer .record .record_bottom p {
  font-size: 0.32rem;
  line-height: 0.48rem;
}